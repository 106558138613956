<template>
    <div>

        <div class="right-menu shipping-right">

            <subHeader pageName="BILLS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'bill'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="billsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == billsList.length"
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Bill No.">Bill No.</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Order No.">Order No.</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Vendor Name">Vendor Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Bill Date">Bill Date</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Due Date">Due Date</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="billsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>

                                    <tr v-if="billsList && billsList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Bills Available</p></td>
                                    </tr>

                                    <tr v-for="(listedBill, index) in billsList" :key="index">

                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewBill(listedBill,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent="checkedItemHandle(listedBill.id)"
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="listedBill.id" 
                                                        :disabled="tourIsActive" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block ends here -->

                                        <!-- bill no. block starts here -->
                                        <td class="mainTableTitle" @click="previewBill(listedBill,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="blog-title-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a bill no."
                                                    v-model="listedBill.bill_no" 
                                                    @focus="billSelection(listedBill)" 
                                                    @blur="handleOTGUpdate(listedBill,'bill_no',index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- bill no. block ends here -->

                                        <!-- order no. block starts here -->
                                        <td class="mainTableTitle" @click="previewBill(listedBill,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="blog-title-field-3">
                                                <input 
                                                    type="text"
                                                    placeholder="Write an order no."
                                                    v-model="listedBill.order_no" 
                                                    @focus="billSelection(listedBill)" 
                                                    @blur="handleOTGUpdate(listedBill,'order_no',index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- order no. block ends here -->

                                        <!--vendor name block starts here -->
                                        <td @click.prevent="previewBill(listedBill,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ 
                                                        listedBill.vendor ? listedBill.vendor.name.length > 15 ? 
                                                        listedBill.vendor.name.substr(0,15) + "..." :  listedBill.vendor.name : '--' 
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!--vendor name block ends here -->

                                        <!-- bill date block starts here -->
                                        <td style="position: relative;" @click.prevent="previewBill(listedBill,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                {{ listedBill.bill_date ? new Date(listedBill.bill_date).toLocaleString("en-US",{year: 'numeric'}) + "-"+
                                                new Date(listedBill.bill_date).toLocaleString("en-US",{month: 'numeric'}) + "-"+
                                                new Date(listedBill.bill_date).toLocaleString("en-US",{day: 'numeric'}) : '--' }}
                                            </div>
                                        </td>
                                        <!-- bill date block ends here -->

                                        <!-- due date block starts here -->
                                        <td style="position: relative;" @click.prevent="previewBill(listedBill,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                {{ listedBill.due_date ? new Date(listedBill.due_date).toLocaleString("en-US",{year: 'numeric'}) + "-"+
                                                new Date(listedBill.due_date).toLocaleString("en-US",{month: 'numeric'}) + "-"+
                                                new Date(listedBill.due_date).toLocaleString("en-US",{day: 'numeric'}) : '--' }}
                                            </div>
                                        </td>
                                        <!-- due date block ends here -->

                                        <!-- action block starts here -->
                                        <td @click="previewBill(listedBill,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(listedBill.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteBillHandle(listedBill,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != billsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="billsBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here -->

                </div>
            </div>

        </div>

        <!-- vue tour -->
        <v-tour name="allBillsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import {MessageBox, Message, Loading} from 'element-ui'
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import moduleStats from "@/components/moduleStats.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    name: 'Bills',
    metaInfo() {
        return {
            title: 'Expenses/Purchases | Bills | YeetCommerce',
        };
    },
    data(){
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //bills start
            billsList:[],
            billsLoading: false,
            tempBill:null,
            //bills end

            //checked items start
            checkedItems:[],
            //checked items end

            //deleting start
            deleteLoader:false,
            deletingItems:[],
            //deleting end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //bills steps
                {
                    target: '#module-search',
                    content: `<strong>Search Bills</strong><br>It enables you to search specific shipping bills.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Bills Table</strong><br>You can preview and edit the added bills by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Bill</strong><br>By clicking this button, you can add a new bill and open a screen to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
            ],
            tourIsActive:false,
            //tour end

        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeBills:'bills_module/bills',
            billsCurrentPage: 'bills_module/currentPage',
            billsLastPage: 'bills_module/lastPage',
            billsSearchKeyword:'bills_module/keyword',
            isPending:'bills_module/isPending',
        })
    },
    watch:{
        'storeBills':{
            handler:function(value){

                this.billsLoading = false;

                this.billsList = value;

            },deep:true
        }
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.billsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.billsCurrentPage != this.billsLastPage){

                    this.$store.dispatch('bills_module/fetchStoreBillsRequest',{size:10, page: this.billsCurrentPage + 1, loadData: 'more'});
                
                }

            }

        },
        //infinite scroll end

        //OTG update start
        billSelection(listedBill){

            this.tempBill = _.cloneDeep(listedBill);

        },
        async handleOTGUpdate(listedBill,field,index){

            if(field == 'bill_no'){

                if(this.tempBill.bill_no != listedBill.bill_no){

                    if(listedBill.bill_no.length == 0){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The bill no. field is required.",
                        });

                        this.billsList[index].bill_no = this.tempBill.bill_no;

                        return

                    }

                    if(listedBill.bill_no.length > 250){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The bill no. field may not be greater than 250 characters.",
                        });

                        this.billsList[index].bill_no = this.tempBill.bill_no;

                        return

                    }

                    if(!listedBill.vendor){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "Vendor needs to be associated with the bill.",
                        });

                        this.billsList[index].bill_no = this.tempBill.bill_no;

                        return

                    }

                    MessageBox.confirm(
                        'You have unsaved changes, save and proceed?',
                        'Confirm',
                        {
                            distinguishCancelAndClose: true,
                            confirmButtonText: 'Save',
                            cancelButtonText: 'Discard Changes',
                        }
                    ).then(async () => {

                        let formData = new FormData();

                        formData.append('field','bill_no');
                        formData.append('bill_id',listedBill.id);

                        formData.append('bill_no',listedBill.bill_no);

                        let loader = Loading.service({
                            text: "The bill is being updated. Please wait!",
                            fullscreen: true,
                        });

                        try{
                            let res = await this.$axios.post('bill/selective/update',formData);
                            if(res.data.status_code == "1058"){

                                this.$notify({
                                    type: 'success',
                                    title: 'Success',
                                    message: res.data.message,
                                });

                                this.$store.commit('bills_module/update_bills_list',res.data.bill)

                                this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                this.$store.commit('gallery_module/CLEAR_MODULE');

                            }
                        }catch(error){

                            this.billsList[index].bill_no = this.tempBill.bill_no;

                            this.tempBill = null;

                            if(error.response){

                                if(error.response.data.error.bill_id){

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.error.bill_id[0],
                                    });

                                }else if(error.response.data.error.bill_no){

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.error.bill_no[0],
                                    });

                                }else if(error.response.data.error.includes('storage limit has been reached')){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        dangerouslyUseHTMLString: true,
                                        message: error.response.data.error,
                                    });

                                }else if(error.response.data.error.includes("doesn't support")){

                                    let subscriptionPath = window.location.origin + "/subscription";

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        dangerouslyUseHTMLString: true,
                                        message: error.response.data.error 
                                        + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                    });

                                }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.message,
                                    });

                                }
                                
                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.message,
                                });

                            }

                        }finally{

                            loader.close();

                        }

                    }).catch((error) => {

                        this.billsList[index].bill_no = this.tempBill.bill_no;

                        this.$notify({
                            type: "info",
                            title: "Changes Discarded",
                            message: "Changes discarded successfully.",
                        });

                        this.tempBill = null;

                    });


                }

            }else if(field == 'order_no'){

                if(this.tempBill.order_no != listedBill.order_no){

                    if(listedBill.order_no.length > 250){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The order no. field may not be greater than 250 characters.",
                        });

                        this.billsList[index].order_no = this.tempBill.order_no;

                        return 

                    }

                    if(!listedBill.vendor){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "Vendor needs to be associated with the bill.",
                        });

                        this.billsList[index].order_no = this.tempBill.order_no;

                        return 

                    }

                    MessageBox.confirm(
                        'You have unsaved changes, save and proceed?',
                        'Confirm',
                        {
                            distinguishCancelAndClose: true,
                            confirmButtonText: 'Save',
                            cancelButtonText: 'Discard Changes',
                        }
                    ).then(async () => {

                        let formData = new FormData();

                        formData.append('field','order_no');
                        formData.append('bill_id',listedBill.id);

                        formData.append('order_no',listedBill.order_no);

                        let loader = Loading.service({
                            text: "The bill is being updated. Please wait!",
                            fullscreen: true,
                        });

                        try{
                            let res = await this.$axios.post('bill/selective/update',formData);
                            if(res.data.status_code == "1058"){

                                this.$notify({
                                    type: 'success',
                                    title: 'Success',
                                    message: res.data.message,
                                });

                                this.$store.commit('bills_module/update_bills_list',res.data.bill)

                                this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                this.$store.commit('gallery_module/CLEAR_MODULE');

                            }
                        }catch(error){

                            this.billsList[index].order_no = this.tempBill.order_no;

                            this.tempBill = null;

                            if(error.response){

                                if(error.response.data.error.bill_id){

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.error.bill_id[0],
                                    });

                                }else if(error.response.data.error.order_no){

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.error.order_no[0],
                                    });

                                }else if(error.response.data.error.includes('storage limit has been reached')){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        dangerouslyUseHTMLString: true,
                                        message: error.response.data.error,
                                    });

                                }else if(error.response.data.error.includes("doesn't support")){

                                    let subscriptionPath = window.location.origin + "/subscription";

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        dangerouslyUseHTMLString: true,
                                        message: error.response.data.error 
                                        + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                    });

                                }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.message,
                                    });

                                }
                                
                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.message,
                                });

                            }

                        }finally{

                            loader.close();

                        }

                    }).catch((error) => {

                        this.billsList[index].order_no = this.tempBill.order_no;

                        this.$notify({
                            type: "info",
                            title: "Changes Discarded",
                            message: "Changes discarded successfully.",
                        });

                        this.tempBill = null;

                    });

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewBill(listedBill,i,field,e){

            if(field == 'input'){
            
                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                
                    this.$router.push({name:'Edit Bill', params:{bill: listedBill, id:listedBill.id}});

                }
            
            }else{

                this.$router.push({name:'Edit Bill', params:{bill: listedBill, id:listedBill.id}});

            }

        },
        //preview form open/close & reset end

        //delete bill start
        deleteBillHandle(listedBill,index){

            if(!this.tourIsActive){
            
                MessageBox.confirm(
                    'Are you sure you want to delete the bill?',
                    'Warning',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                    }
                ).then(async ()=>{

                    this.deletingItems.push(listedBill.id);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The bill is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post('/deleteBill', {id: listedBill.id});
                        if(res.data.status_code == '1059'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            // this.$store.commit('bills_module/delete_bills_list',index);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedBill.id){
                                    
                                    return item;
                                    
                                }

                            });

                            this.billsLoading = true;

                            this.$store.dispatch('bills_module/fetchStoreBillsRequest',{size:10, page:1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.id[0],
                                });

                            }else if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });
                            
                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false

                    }

                }).catch(() => {})

            }

        },
        //delete bill end

        //bulk action start
        billsBulkActionHandle(action){

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'delete'){

                    let formData = new FormData();

                    formData.append('resource_type', 'bill');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems)
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The selected bills are being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post("/bulk-action",formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Bills have been successfully removed.",
                            });

                            this.checkedItems = [];
                            this.billsLoading = true;
                            
                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            // this.$store.commit('bills_module/bills_bulk_action',payload);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                            this.$store.dispatch('bills_module/fetchStoreBillsRequest',{size:10, page:1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One of the bills may have been deleted or isn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        //search region start
        searchBill(keyword){

            this.$refs.module_search.searchedKeyword = keyword
            
            this.$refs.module_search.search();

        },
        //search region end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive){

                if((this.checkedItems.length == this.billsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.billsList.length){

                    this.checkedItems = [];
                    
                    this.billsList.forEach(bill => {

                        if(bill.id != -1){

                            this.checkedItems.push(bill.id);

                        }

                    });

                }

            }

        },
        checkedItemHandle(billId){

            if(!this.tourIsActive){

                let index = this.checkedItems.findIndex(item => item == billId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(billId)

                }

            }

        },
        clearSelection(){
            
            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        //other methods end

        //tour methods start
        startBillsTour(){

            this.$tours.allBillsTour.start();

            this.tourIsActive = true;

        },
        handleNextStep(currentStep){
        },
        handlePreviousStep(currentStep){
        },
        handleTourStop(){

            this.tourIsActive = false;

        },
        handleTourFinish(){

            this.tourIsActive = false;

        },
        handleTourSkip(){

            this.tourIsActive = false;

        },
        //tour methods end
    },
    mounted(){

        this.$refs.billsTable.addEventListener('scroll', this.handleScrollEvent)

        if(this.$route.query.search){
      
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    async beforeMount(){

        this.billsLoading = true;
        try{
            if(!this.storeBills || this.storeBills.length == 0){

                this.$store.dispatch('bills_module/fetchStoreBillsRequest',{size:10, page:1, loadData: 'new'});

            }else{

                this.billsList = this.storeBills;
                this.billsLoading = false;

            }

        }catch(error){

            this.billsLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

    },
}
</script>

<style>

</style>